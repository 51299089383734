import React, { useEffect, useState } from 'react'
import { Table, Form, Pagination  } from 'react-bootstrap'
import axios from 'axios'
import {backendUrlApiGateway} from "../constants"
import {useKeycloak} from "@react-keycloak/web";
const OrdersTable = () => {
    const { keycloak } = useKeycloak()
    const [orders, setOrders] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage] = useState(25)
    useEffect(() => {
        axios.get(`${backendUrlApiGateway}orders` ,
            {
                headers: {
                    //'Content-Type': 'application/json',
                    "Authorization":`Bearer ${keycloak.token}`
                }
            })
            .then(response => setOrders(response.data.orders))
            .catch(error => console.error('Error fetching orders:', error))
    }, [])
    const handleSearch = (query) => {
        setSearchQuery(query)
        setCurrentPage(1)
    }
    const getFilteredOrders = () => {
        return (searchQuery) ? orders.filter( (order) => order.toString().toLowerCase().includes(searchQuery.toLowerCase())) : orders
    }
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentOrders = getFilteredOrders().slice(indexOfFirstItem, indexOfLastItem)
    const totalPages = Math.ceil(getFilteredOrders().length / itemsPerPage)
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }
    return (
        <div>
        <Form.Group controlId="searchForm">
            <Form.Control type="text" placeholder="Search orders" value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)} />
        </Form.Group>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Order ID</th>
                <th>Customer ID</th>
                <th>Total Amount</th>
                <th>Created on</th>
                <th>Updated last</th>
                <th>State</th>
            </tr>
            </thead>
            <tbody>
            {currentOrders.map(order => (
                <tr key={order.id}>
                    <td> { order.id } </td>
                    <td>{order.customer_id}</td>
                    <td>${order.total_money.amount.toFixed(2)}</td>
                    <td>{order.created_at}</td>
                    <td>{order.updated_at}</td>
                    <td>{order.state}</td>
                </tr>
            ))}
            </tbody>
        </Table>
        <Pagination>
            {Array.from({ length: totalPages }).map((_, index) => (
                <Pagination.Item key={index + 1} active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                >{index + 1}</Pagination.Item>
            ))}
        </Pagination>
        </div>
    )
}
export default OrdersTable