import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import {useKeycloak} from "@react-keycloak/web"
import {backendUrlApiGateway} from "../constants"
const Profile = () => {
    const { keycloak, initialized} = useKeycloak()
    const [apiResponseMessage, setAPIResponseMessage] = useState('')
    const [isDropdownVisible, setDropdownVisible] = useState(false)
    const { t } = useTranslation()
    // if (isLoading) { return <div>Loading ...</div> }
    const toggleDropdown = () => { setDropdownVisible(!isDropdownVisible) }

    const securedAPITest = (ep) => {
        fetch(`${backendUrlApiGateway}${ep}`, {
            method: "GET",
            "Content-Type": "application/json",
            headers: {
                "Authorization":`Bearer ${keycloak.token}`,
                // "scope":"*"
            },
        })
            .then((res) => {
                // if (!res.ok) { throw new Error(`HTTP error! Status: ${res.status}`) }
                return res.json()
            })
            .then((resJson) => {
                console.log(resJson)
                setAPIResponseMessage(resJson.message)
            })
            .catch((e) => console.log(e))
    }
    return (
        // isAuthenticated && (
            <div className="header-menu-wrapper">
                <div className="dropdown">
                    <button className="dropbtn" onClick={toggleDropdown} style={{border: "none", background: "none"}}>
                        {/*<img loading="lazy" src={user.picture} alt={user.name}*/}
                        {/*    style={{ width: '64px', height: '64px', borderRadius: '50%',*/}
                        {/*        objectFit: 'cover', position: 'relative', left: '-20px'*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <div>
                            <h1>
                                Welcome, {keycloak.authenticated ? keycloak.tokenParsed.name : "Guest"}
                            </h1>
                        </div>
                    </button>
                            <button onClick={() => securedAPITest("public")}>Test Public API</button>
                            <button onClick={() => securedAPITest("private")}>Test private API</button>
                            <button onClick={() => securedAPITest("priv/role")}>Test priv/role API</button>
                            <button onClick={() => securedAPITest("priv/noaccess")}>Test priv/noaccess API</button>
                            <p>Response Message: {apiResponseMessage}</p>


                    {isDropdownVisible && (
                        <div className="dropdown-content"
                             style={{ display: isDropdownVisible ? 'block' : 'none',
                                left: '50%', transform: 'translateX(-50%)' }}
                        >
                            {/*{isAuthenticated ? <LogoutButton /> : <LoginButton />}*/}
                            <a href="/profile-page">{t('profilePage.profiledrop')}</a>
                        </div>
                    )}
                </div>
            </div>
        // )
    )
}
export default Profile