import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import editIcon from './images/editIcon.svg'
import axios from 'axios'
// import { useAuth0 } from '@auth0/auth0-react'
import { backendUrlApiGateway } from '../constants.js'
//import SquarePaymentComponent from './SquarePaymentComponent'
//import Button from 'bootstrap/dist/css/bootstrap.min.css'
import ArtworkCarousel from './ArtworkCarousel'
import { initialArtworkData } from '../forms/artworkData'
import { useTranslation } from 'react-i18next'
import { useCart } from './CartContext'
import {useKeycloak} from "@react-keycloak/web";

const ArtworkDetails = () => {
    const { t } = useTranslation()
    const handleSuccess = (responseData) => { console.log("Payment successful!", responseData) }
    const handleError = (error) => { console.error("Error making the payment", error) }
    const params = useParams()
    const [artworkData, setArtworkData] = useState(initialArtworkData)
    const { keycloak } = useKeycloak()
    // const { isAuthenticated } = useAuth0()
    const { addToCart, showWarning } = useCart()

    useEffect(() => {
        axios
            .get(`${backendUrlApiGateway}artworks/${params.artworkId}`)
            .then((result) => {
                setArtworkData(result.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [params.artworkId])

    const navigate = useNavigate()
    const handleBack = () => {
        navigate(-1)
    }

    const handleAddToCart = () => {
        addToCart(artworkData)
    }
    return (
        <div className="container">
            <div className="row">
                {/*{isAuthenticated && (*/}
                    <div>
                        <div className="">
                            <div className="col-md-12 ">
                                {/*{keycloak.tokenParsed.realm_access.roles.includes('RAB') && (*/}
                                    <a href={`/update-artwork/${params.artworkId}`}>
                                        <img src={editIcon} alt="image description" className="editIcon"/>
                                    </a>
                                {/*)}*/}
                            </div>
                        </div>
                    </div>
                 {/*)}*/}
                <h1 className="artwork-details-title dancing-script" style={{ fontSize: '80px' }}>
                    {artworkData.name}
                </h1>
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <ArtworkCarousel
                            artworkImages={artworkData.artworkImages || []}
                            cloudinaryImageUrl={artworkData.cloudinaryImageUrl}
                            className=""
                        />
                    </div>
                </div>
                <div className="playfair-display-italic">
                    <br />
                    <h1 className="artwork-details-subtitle">{t('artworkDetailsPage.aboutTheArtwork')}</h1>
                    <p className="artwork-details">{artworkData.description}</p>
                    <br />
                    <h1 className="artwork-details-subtitle">{t('artworkDetailsPage.detailsAndDimensions')}</h1>
                    <p className="artwork-details">
                        {artworkData.name} ${artworkData.price}
                    </p>
                    <p className="artwork-details">
                        {t('artworkDetailsPage.size')}: {artworkData.length}m (W) x {artworkData.height}m (H)
                    </p>
                    <p className="artwork-details">{t('artworkDetailsPage.weight')}: {artworkData.weight}kg</p>
                </div>
                <div className="text-center">
                    { artworkData.status === 'LISTED' &&
                    <button
                        type="button"
                        onClick={handleAddToCart}
                        className="btn btn-primary"
                        style={{
                            marginRight: '10px',
                            backgroundColor: '#3498db',
                            color: '#ffffff',
                            padding: '10px 15px',
                            borderRadius: '5px',
                            marginBottom: '20px',
                            marginTop: '20px',
                            marginLeft: '5px',
                        }}
                    >
                        {t('galleryPage.addCart')}
                    </button>}
                    <button
                        type="button"
                        onClick={handleBack}
                        className="btn btn-primary"
                        style={{
                            marginRight: '10px',
                            backgroundColor: '#3498db',
                            color: '#ffffff',
                            padding: '10px 15px',
                            borderRadius: '5px',
                            marginBottom: '20px',
                            marginTop: '20px',
                            marginLeft: '5px',
                        }}
                    >
                        {t('checkoutPage.back')}
                    </button>
                    {showWarning && <div className="WarningContainer"><p className="WarningText">{t('galleryPage.itemAlreadyInCart')}</p></div>}
                </div>
            </div>
        </div>
    )
}

export default ArtworkDetails

