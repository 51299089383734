import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from 'react-router-dom'
import axios from 'axios'
import {backendUrlApiGateway} from '../constants.js'
import FileUpload from "./BaseImageUpload"
import {initialArtworkData} from "./artworkData"
import Button from 'react-bootstrap/Button'
import {useKeycloak} from "@react-keycloak/web";
function UpdateArtworkForm(){
    const {id} = useParams()
    const {keycloak} = useKeycloak()
    const navigate = useNavigate()
    const [artworkData, setArtworkData] = useState(initialArtworkData)
    const [imageUrl, setImageUrl] = useState('')
    const [imageUrls, setImageUrls] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(artworkData.status)

    const handleSingleImageUpload = (url) => {
        setImageUrl(url)
        setArtworkData({...artworkData, cloudinaryImageUrl: url})
    }
    const handleMultipleImageUpload = (url) => {
        setArtworkData((prevData) => ({
            ...prevData,
            artworkImages: [...prevData.artworkImages, url],
        }))
    }
    const handleDeleteArtworkImage = (deletedUrl) => {
        const updatedImageUrls = artworkData.artworkImages.filter(url => url !== deletedUrl)
        setArtworkData(prev => ({...prev, artworkImages: updatedImageUrls}))
    }
    const handleInputChange = (e) => {
        const {name, value} = e.target
        setArtworkData({...artworkData, [name]: value})
    }
    const handleCheckboxChange = (e) => {
        const {name, checked} = e.target
        setArtworkData({...artworkData, [name]: checked})
    }

    const handleBack = () => { navigate(-1) }
    const handleSelectChange = (e) => {
        setArtworkData({...artworkData, status:e.target.value})
        console.log(artworkData.status)
        console.log(e.target.value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (
            !artworkData.name ||
            !artworkData.description ||
            !artworkData.price ||
            !artworkData.length ||
            !artworkData.height ||
            !artworkData.yearOfCreation ||
            !artworkData.publishedDate
        ) { return }
        const hasExistingImages = artworkData.artworkImages && artworkData.artworkImages.length > 0
        const hasNewImage = imageUrl.length > 0
        const hasNewImages = imageUrls.length > 0
        if (!hasExistingImages && !hasNewImage && !hasNewImages) {
            alert('Please upload at least one image.')
            return
        }
        const isConfirmed = window.confirm('Are you sure you want to update this artwork?')
        if (isConfirmed) { handleConfirm() }
    }
    const handleConfirm = (e) => {
        const updatedArtworkData = {
            ...artworkData,
            cloudinaryImageUrl: imageUrl || artworkData.cloudinaryImageUrl,
            artworkImages: imageUrls.length > 0 ? imageUrls : artworkData.artworkImages
        }
        console.log("Submitting updated artwork data:", updatedArtworkData)
        axios.put(`${backendUrlApiGateway}artworks/${id}`, updatedArtworkData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${keycloak.token}`
            }
        })
            .then(response => {
                // Handle the response data
                console.log('Response data:', response.data)
                setImageUrl('')
                setImageUrls([])
                alert(`Artwork updated successfully: ${artworkData.name}`)
                handleBack()
            })
            .catch(error => { console.error('Error:', error) })
    }
    // useEffect(() => {
    //     axios.get(`${backendUrlApiGateway}artworks/`+id)
    //         // headers: {
    //         //     'Content-Type': 'application/json',
    //         //     "Authorization":`Bearer ${keycloak.token}`,
    //         // }
    //         .then(result => setArtworkData(result.data) )
    //         .catch(error => console.log(error))
    //
    // }, [])
    useEffect(() => {
        axios.get(`${backendUrlApiGateway}artworks/` + id, {
            headers: {
                //'Authorization': `Bearer ${keycloak.token}`
            }
        })
            .then(result => {
                setArtworkData(result.data)
            })
            .catch(error => {
                console.error('Error fetching artwork data:', error)
            })
    }, [id, keycloak.token])
    return (
        <div className="update-artwork-page-container p-8 bg-white-100 rounded-lg shadow-lg">
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '10px' }}>
                <button
                    type="button"
                    onClick={handleBack}
                    style={{
                        marginRight: '10px',
                        backgroundColor: '#3498db',
                        color: '#ffffff',
                        padding: '10px 15px',
                        borderRadius: '5px',
                    }}
                >Back</button>
            </div>
            <h1 className="text-3xl font-bold mb-6 text-center">Update Artwork</h1>
            <form onSubmit={handleSubmit} className="mb-6">
                {/*all fields are required to submit*/}
                <div className="grid grid-cols-1 gap-6">
                    <div className="flex flex-col mb-4">
                        <div className="mb-4">
                            <label className="form-label block mb-2">Artwork Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={artworkData.name}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                placeholder="Artwork Name"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label block mb-2">Artwork Description:</label>
                            <textarea name="description" value={artworkData.description} onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                placeholder="Artwork Description"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="mb-4 m-lg-4">
                            <label>Display Image: </label>
                            <FileUpload
                                onImageUpload={handleSingleImageUpload} multiple={false} uploadType="displayImage"
                                initialImages={artworkData.cloudinaryImageUrl ? [artworkData.cloudinaryImageUrl] : []} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Length (inches):</label>
                            <input
                                type="number"
                                name="length"
                                value={artworkData.length}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Height (inches):</label>
                            <input
                                type="number"
                                name="height"
                                value={artworkData.height}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Weight (lbs):</label>
                            <input
                                type="number"
                                name="weight"
                                value={artworkData.weight}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label block mb-2">Price: {/*${artworkData.price.toLocaleString()}*/}</label>
                            <input
                                type="number"
                                name="price"
                                value={artworkData.price}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="flex items-center">
                            <label>Status:</label>
                            <select value={artworkData.status}  onChange={handleSelectChange} name="status">
                                <option value="LISTED">Listed</option>
                                <option value="UNLISTED">Archived</option>
                                <option value="SOLD">Sold</option>
                                <option value="INPROGRESS" >In Progress</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label block mb-2">Year of Creation:</label>
                            <input
                                type="number"
                                name="yearOfCreation"
                                value={artworkData.yearOfCreation}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                placeholder="Year of Creation"
                                required
                                style={{width: '30%'}}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label block mb-2">Published Date:</label>
                            <input
                                type="date"
                                name="publishedDate"
                                value={artworkData.publishedDate}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{width: '30%'}}
                            />
                        </div>
                        <div className="mb-4 m-lg-4">
                            <label>Additional Images: </label>
                            <FileUpload onImageUpload={handleMultipleImageUpload}
                                        onImageDelete={handleDeleteArtworkImage}
                                        multiple={true}
                                        initialImages={artworkData.artworkImages || []}
                                        uploadType="artworkImages"
                            ></FileUpload>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <Button type="button" onClick={handleSubmit} variant="success">Update Artwork</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default UpdateArtworkForm

