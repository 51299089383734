import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { useCart } from '../components/CartContext'
import { Link, useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import React from 'react'
import './Cart.css'
import { useTranslation } from "react-i18next"
import { useKeycloak } from "@react-keycloak/web"

const Cart = () => {
    const { cartItems, removeFromCart } = useCart()
    const isCartEmpty = cartItems.length === 0
    const totalPrice = cartItems.reduce((total, item) => total + item.price, 0)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { keycloak } = useKeycloak()

    const handleBack = () => {
        navigate('/gallery')
    }
    const handleCheckout = () => {
        if (!keycloak.authenticated) {
            keycloak.login()
        } else {
            navigate('/checkout')
        }
    }

    return (
        // <Container className="shopping-cart-container py-5">
        <Container style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px', marginTop: '5%', marginBottom: '5%'}}>
            <div className="">
                <h1 className="text-center dancing-script">{t('shoppingCartPage.shoppingCart')}</h1>
                {isCartEmpty ? (
                    <div className="empty-cart-container text-center">
                        <FontAwesomeIcon icon={faShoppingCart} size="4x" className="text-gray-400 mb-4" />
                        <h2 className="text-2xl font-bold mb-4 ">{t('shoppingCartPage.emptyCart')}</h2>
                        <p className="text-gray-600 mb-6">{t('shoppingCartPage.startShopping')}</p>
                        <Button href="/gallery" style={{
                            marginRight: '10px',
                            backgroundColor: '#3498db',
                            color: '#ffffff',
                            padding: '10px 15px',
                            borderRadius: '5px',
                            marginBottom: '20px',
                            marginTop: '20px',
                            marginLeft: '5px',
                        }}>{t('shoppingCartPage.visitGallery')}</Button>
                    </div>
                ) : (
                    <div className="cart-items-container mb-5">
                        {cartItems.map((item) => (
                            <div key={item.artworkId} className="card mb-3">
                                <div className="row g-lg-12">
                                    <div className="col-md-4"> {/* This div takes 4 out of 12 columns on medium or larger screens */}
                                        <Link to={`/gallery/${item.artworkId}`}>
                                            <img
                                                src={item.cloudinaryImageUrl}
                                                alt={item.name}
                                                style={{ maxWidth: '160px', height: 'auto', margin: '10%', borderRadius: '8px',}}
                                            />
                                        </Link>
                                    </div>
                                    <div className="col-md-8 d-flex align-items-center">
                                        <div className="card-body text-center"  >
                                            <h5 className="card-title dancing-script">{item.name}</h5>
                                            <p className="card-text playfair-display-italic">{t('shoppingCartPage.price')} ${item.price}</p>
                                            <p className="card-text playfair-display-italic">{t('artworkDetailsPage.size')}: {item.length}m (W) x {item.height}m (H)</p>
                                            <p className="card-text playfair-display-italic">{t('artworkDetailsPage.weight')}: {item.weight}kg</p>
                                            <Button variant="danger" onClick={() => removeFromCart(item.artworkId)} className="m-lg-auto">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {!isCartEmpty && (
                    <div className="text-center playfair-display">
                        {cartItems.map((item) => (<p key={item.artworkId}>{item.name}: ${item.price}</p>))}
                        <hr />
                        <p>{t('shoppingCartPage.totalPrice')} ${totalPrice.toFixed(2)}</p>
                    </div>
                )}
                {!isCartEmpty && (
                    <div className="text-center">
                            <Button variant="primary" onClick={handleCheckout} style={{ marginRight: '10px',
                                backgroundColor: '#3498db',
                                color: '#ffffff',
                                padding: '10px 15px',
                                borderRadius: '5px',
                                marginBottom: '20px',
                                marginTop: '20px',
                                marginLeft: '5px', }}>
                                {t('shoppingCartPage.checkout')}
                            </Button>
                        <button
                            type="button"
                            onClick={handleBack}
                            className="btn btn-primary"
                            style={{
                                marginRight: '10px',
                                backgroundColor: '#3498db',
                                color: '#ffffff',
                                padding: '10px 15px',
                                borderRadius: '5px',
                                marginBottom: '20px',
                                marginTop: '20px',
                                marginLeft: '5px',
                            }}
                        >
                            {t('shoppingCartPage.returnToGallery')}
                        </button>
                    </div>
                )}
            </div>
        </Container>
    )
}
export default Cart

