import React, {useEffect, useState} from 'react'
import axios from "axios"
import {backendUrlApiGateway} from '../constants.js'
import FileUpload from "./BaseImageUpload"
import {initialArtworkData} from './artworkData'
import "./Forms.css"
import {useNavigate} from "react-router-dom"
import {useKeycloak} from "@react-keycloak/web";
//import Button from 'react-bootstrap/Button'

const AddArtworkForm = () => {
    const {keycloak} = useKeycloak()
    const navigate = useNavigate()
    const [artworkData, setArtworkData] = useState(initialArtworkData)
    const [error, setError] = useState("")
    const [imageUrl, setImageUrl] = useState('')
    const [imageUrls, setImageUrls] = useState([])

    const handleSingleImageUpload = (url) => { setImageUrl(url) }
    const handleInputChange = (e) => {
        const {name, value} = e.target
        setArtworkData({...artworkData, [name]: value})
    }
    const handleMultipleImageUpload = (url) => { setImageUrls(prevUrls => [...prevUrls, url]) }
    const handleCheckboxChange = (e) => {
        const {name, checked} = e.target
        setArtworkData({...artworkData, [name]: checked})
    }
    const handleBack = () => { navigate(-1) }
    const handleSelectChange = (e) => { setArtworkData({...artworkData, status:e.currentTarget.value}) }
    const handleSubmit = async (e) => {
        if (
            !artworkData.name ||
            !artworkData.description ||
            !artworkData.price ||
            !artworkData.length ||
            !artworkData.height ||
            !artworkData.yearOfCreation ||
            !artworkData.publishedDate
        ) {
            alert('Please fill out all required fields.')
            return
        }
        if (!imageUrl && imageUrls.length === 0) {
            alert('Please upload at least one image.')
            return
        }
        const isConfirmed = window.confirm('Are you sure you want to add this artwork?')
        if (isConfirmed) { await handleConfirm() }
    }
    const handleConfirm = async () => {
        const payload = {...artworkData, cloudinaryImageUrl: imageUrl, artworkImages: imageUrls}
        try {
            const response = await axios.post(`${backendUrlApiGateway}artworks`, payload ,
                {
                headers: {
                    //'Content-Type': 'application/json',
                    "Authorization":`Bearer ${keycloak.token}`
                }
            })
            console.log('Server response:', response.data)
            alert(`Artwork added successfully: ${artworkData.name}`)
            handleBack()
        } catch (error) {
            console.error("Error submitting new artwork", error)
            alert('Error occurred while adding artwork.')
        }
    }
    const handleClear = () => { setArtworkData(initialArtworkData) }
    return (
        <div className="add-artwork-page-container p-8 bg-white-100 rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold mb-6 text-center">Add Artwork</h1>
            <form onSubmit={handleSubmit} className="mb-6">
                <div className="grid grid-cols-1 gap-6">
                    <div className="flex flex-col mb-4">
                        <div className="mb-4">
                            <label className="form-label block mb-2">Artwork Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={artworkData.name}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                placeholder="Artwork Name"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="form-label block mb-2">Artwork Description:</label>
                            <textarea name="description" value={artworkData.description} onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                placeholder="Artwork Description"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="mb-4 m-lg-4">
                            <label>Display Image: </label>
                            <FileUpload onImageUpload={handleSingleImageUpload} multiple={false} uploadType="displayImage"/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Length (inches):</label>
                            <input
                                type="number"
                                name="length"
                                value={artworkData.length}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Height (inches):</label>
                            <input
                                type="number"
                                name="height"
                                value={artworkData.height}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{width: '30%'}}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Weight (lbs):</label>
                            <input
                                type="number"
                                name="weight"
                                value={artworkData.weight}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{width: '30%'}}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label block mb-2">Price: {/*${artworkData.price.toLocaleString()}*/}</label>
                            <input
                                type="number"
                                name="price"
                                value={artworkData.price}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="flex items-center">
                            <label>Status:</label>
                            <select value={artworkData.status} onChange={handleSelectChange} name="status">
                                <option value="LISTED">Listed</option>
                                <option value="UNLISTED">Archived</option>
                                <option value="SOLD">Sold</option>
                                <option value="INPROGRESS">In Progress</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label block mb-2">Year of Creation:</label>
                            <input
                                type="number"
                                name="yearOfCreation"
                                value={artworkData.yearOfCreation}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                placeholder="Year of Creation"
                                required
                                style={{width: '30%'}}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label block mb-2">Published Date:</label>
                            <input
                                type="date"
                                name="publishedDate"
                                value={artworkData.publishedDate}
                                onChange={handleInputChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 m-lg-2"
                                required
                                style={{ width: '30%' }}
                            />
                        </div>
                        <div className="mb-4 m-lg-4">
                            <label>Additional Images: </label>
                            <FileUpload onImageUpload={handleMultipleImageUpload} multiple={true} uploadType="artworkImages"/>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <button
                            type="button"
                            onClick={handleClear}
                            style={{
                                marginRight: '10px',
                                backgroundColor: '#3498db',
                                color: '#ffffff',
                                padding: '10px 15px',
                                borderRadius: '5px',
                            }}
                        >Clear</button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            style={{
                                backgroundColor: '#3498db',
                                color: '#ffffff',
                                padding: '10px 15px',
                                borderRadius: '5px',
                            }}
                        >Add Artwork</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default AddArtworkForm