import './App.css'
import Artworks from "./components/Artworks"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import About from "./pages/About"
import Contact from "./pages/Contact"
import Cart from "./pages/Cart"
import ArtworkDetails from "./components/ArtworkDetails"
import Profile from "./components/Profile"
import AddArtworkForm from "./forms/Add-Artwork-Form"
import UpdateArtworkForm from "./forms/Update-Artwork-Form"
import BaseImageUpload from "./forms/BaseImageUpload"
import Header from "./components/Header"
import ProfilePage from "./components/ProfilePage"
import {CartProvider} from './components/CartContext'
import CheckoutPage from "./components/CheckoutPage"
import AddCommissionForm from "./forms/Add-Commission-Form"
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Footer from "./pages/Footer"
import ErrorPage from "./components/ErrorPage"
import './i18n'
import {ReactKeycloakProvider, useKeycloak} from "@react-keycloak/web"
import keycloakop from "./components/auth/keycloak";
function App() {
    const {keycloak } = useKeycloak()
    return (
        <div className="App d-flex flex-column min-vh-100 text-center">
             {/*<ReactKeycloakProvider authClient={keycloakop} >*/}
                <CartProvider>
                    <BrowserRouter>
                        <Header />
                        <div className="flex-grow-1">
                        <Routes>
                            <Route index element={<Artworks />} />
                            <Route path="/gallery" element={<Artworks />} />
                            <Route path="/gallery/:artworkId" element={<ArtworkDetails />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/cart" element={<Cart />} />
                            {keycloak.authenticated ? (
                                <>
                                    {/*{keycloak.tokenParsed.realm_access.roles.includes('RAB') ? (*/}
                                    {/*    <>*/}
                            <Route path="/add-artwork" element={<AddArtworkForm />} />

                            <Route path="/update-artwork/:id" element={<UpdateArtworkForm />} />
                            <Route path="/image" element={<BaseImageUpload />} />
                            <Route path="/profile" element={<Profile />} />
                            {/*            </>*/}
                            {/*) : null}*/}
                            <Route path="/profile-page" element={<ProfilePage />} />
                            <Route path="/profile-page" element={<ProfilePage />} />
                            <Route path="/add-commission" element={<AddCommissionForm />} />
                            <Route path="/checkout" element={<CheckoutPage />} />
                                </>
                            ) : null}
                            <Route path="*" element={<ErrorPage />} />
                        </Routes>
                        </div>
                    </BrowserRouter>
                </CartProvider>
             {/*</ReactKeycloakProvider>*/}
            <Footer />
        </div>
    )
}
export default App