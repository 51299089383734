// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-lg { border-radius: 10px; }

.border { border: 2px solid #e5e7eb;}

.border-blue-500 {
    border-color: #3b82f6;
}

.form-range {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.form-range::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
    -webkit-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}

.form-range:hover { opacity: 1; }

.form-range::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border: 0;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
}

.form-range::-ms-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/forms/Forms.css"],"names":[],"mappings":"AAAA,cAAc,mBAAmB,EAAE;;AAEnC,UAAU,yBAAyB,CAAC;;AAEpC;IACI,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;IACxB,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IAEI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,+CAAuC;IAAvC,uCAAuC;AAC3C;;AAEA,oBAAoB,UAAU,EAAE;;AAEhC;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".rounded-lg { border-radius: 10px; }\n\n.border { border: 2px solid #e5e7eb;}\n\n.border-blue-500 {\n    border-color: #3b82f6;\n}\n\n.form-range {\n    -webkit-appearance: none;\n    width: 100%;\n    height: 2px;\n    background: #ddd;\n    outline: none;\n    opacity: 0.7;\n    transition: opacity 0.2s;\n}\n\n.form-range::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    appearance: none;\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    background: #007bff;\n    cursor: pointer;\n    transition: background 0.3s ease-in-out;\n}\n\n.form-range:hover { opacity: 1; }\n\n.form-range::-moz-range-thumb {\n    width: 15px;\n    height: 15px;\n    border: 0;\n    border-radius: 50%;\n    background: #007bff;\n    cursor: pointer;\n}\n\n.form-range::-ms-thumb {\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    background: #007bff;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
