import React, {useEffect, useState} from "react"
import Button from 'react-bootstrap/Button'
import axios from "axios"
import {backendUrlApiGateway} from "../constants"
import SingleArtwork from "./SingleArtwork"
import {Tab, Tabs} from "react-bootstrap"
import OrdersTable from "./OrdersTable"
import JSONParser from "./JSONParser"
import {useTranslation} from "react-i18next"

const ProfilePage = () => {
    const [arts, setArts] = useState([])
    const { t } = useTranslation()
    useEffect(() => {
        (async () => {
            try {
                axios.get(`${backendUrlApiGateway}artworks/displayed`, {
                    accept: "application/json", 'Content-Type': 'text/event-stream-value;charset=UTF-8'
                }).then(res => { setArts(JSONParser(res.data)) })
            } catch (error) { console.error('Error fetching data:', error) }
        })()
    }, [])
    function statusVerifier(param) { return <SingleArtwork params={param}/> }
    return (
        <div>
            <h1 className="text-xl font-bold mb-4 text-gray-900 dancing-script">{t('profilePage.profile')}</h1>
            <div>
                <ul>
                    {/*<img src={user.picture} alt={user.name}/>*/}
                    {/*<li><h2 className="text-4xl font-bold mb-6 text-center text-gray-800">Email: </h2>*/}
                    {/*<h4>{user.email}</h4></li>*/}
                    {/*<li><h2 className="text-4xl font-bold mb-6 text-center text-gray-800">{t('profilePage.name')}: </h2>*/}
                    {/*<h4>{user.name}</h4></li>*/}
                </ul>
            </div>
            <a href="/add-artwork"><Button variant="success">Create Artwork Listing</Button></a>
            <br/>
            <br/>
            <Tabs className="mb-3">
                <Tab eventKey="listed" title="Listed">
                    <div className="artwork-gallery">
                        {arts.map((art) => (art.status === 'LISTED' && statusVerifier(art)))}
                    </div>
                </Tab>
                <Tab eventKey="unlisted" title="Unlisted">
                    <div className="artwork-gallery">
                        {arts.map((art) => (art.status === 'UNLISTED' && statusVerifier(art)))}
                    </div>
                </Tab>
                <Tab eventKey="SOLD" title="Sold">
                    <div className="artwork-gallery">
                        {arts.map((art) => (art.status === 'SOLD' && statusVerifier(art)))}
                    </div>
                </Tab>
                <Tab eventKey="INPROGRESS" title="In Progress">
                    <div className="artwork-gallery">
                        {arts.map((art) => (art.status === 'INPROGRESS' && statusVerifier(art)))}
                    </div>
                </Tab>
                <Tab eventKey="Payments" title="Orders"> <OrdersTable /> </Tab>
            </Tabs>
        </div>
    )
}
export default ProfilePage

// import React, {useEffect, useState} from "react"
// import Button from 'react-bootstrap/Button'
// import axios from "axios"
// import {backendUrlApiGateway} from "../constants"
// import SingleArtwork from "./SingleArtwork"
// import {Tab, Tabs} from "react-bootstrap"
// import OrdersTable from "./OrdersTable"
// import JSONParser from "./JSONParser"
// import {useTranslation} from "react-i18next"
// import {useKeycloak} from "@react-keycloak/web";
//
// const ProfilePage = () => {
//     //const { keycloak} = useKeycloak()
//     const [arts, setArts] = useState([])
//     const { t } = useTranslation()
//     useEffect(() => {
//         (async () => {
//             try {
//                 axios.get(`${backendUrlApiGateway}artworks/displayed`, { headers: {
//                     accept: "application/json", 'Content-Type': 'text/event-stream-value;charset=UTF-8'
//                 }}).then(res => { setArts(JSONParser(res.data)) })
//             } catch (error) { console.error('Error fetching data:', error) }
//         })()
//     }, [])
//     // useEffect(() => {
//     //     const fetchData = async () => {
//     //         try {
//     //             const response = await axios.get(`${backendUrlApiGateway}artworks/displayed`, {
//     //                 headers: {
//     //                     'Authorization': `Bearer ${keycloak.token}`,
//     //                     'accept': 'application/json',
//     //                     'Content-Type': 'text/event-stream-value;charset=UTF-8'
//     //                 }
//     //             })
//     //             setArts(JSONParser(response.data))
//     //         } catch (error) {
//     //             console.error('Error fetching data:', error)
//     //         }
//     //     };
//     //
//     //     fetchData()
//     // }, [keycloak.token])
//
//     function statusVerifier(param) { return <SingleArtwork params={param}/> }
//     return (
//         <div>
//             <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">{t('profilePage.profile')}</h1>
//             <div>
//                 <ul>
//                     {/*<img src={user.picture} alt={user.name}/>*/}
//                     {/*<li><h2 className="text-4xl font-bold mb-6 text-center text-gray-800">Email: </h2>*/}
//                         {/*<h4>{user.email}</h4></li>*/}
//                     {/*<li><h2 className="text-4xl font-bold mb-6 text-center text-gray-800">{t('profilePage.name')}: </h2>*/}
//                         {/*<h4>{user.name}</h4></li>*/}
//                 </ul>
//             </div>
//             <a href="/add-artwork"><Button variant="success">Create Artwork Listing</Button></a>
//             <br/>
//             <br/>
//             <Tabs className="mb-3">
//                 <Tab eventKey="listed" title="Listed">
//                         <div className="artwork-gallery">
//                             {arts.map((art) => (art.status === 'LISTED' && statusVerifier(art)))}
//                         </div>
//                 </Tab>
//                 <Tab eventKey="unlisted" title="Unlisted">
//                     <div className="artwork-gallery">
//                         {arts.map((art) => (art.status === 'UNLISTED' && statusVerifier(art)))}
//                     </div>
//                 </Tab>
//                 <Tab eventKey="SOLD" title="Sold">
//                     <div className="artwork-gallery">
//                         {arts.map((art) => (art.status === 'SOLD' && statusVerifier(art)))}
//                     </div>
//                 </Tab>
//                 <Tab eventKey="INPROGRESS" title="In Progress">
//                     <div className="artwork-gallery">
//                         {arts.map((art) => (art.status === 'INPROGRESS' && statusVerifier(art)))}
//                     </div>
//                 </Tab>
//                 <Tab eventKey="Payments" title="Orders"> <OrdersTable /> </Tab>
//             </Tabs>
//         </div>
//     )
// }
// export default ProfilePage