import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHeart, faShoppingCart} from "@fortawesome/free-solid-svg-icons"
import React, {useState} from "react"
import {useCart} from "./CartContext"
import {useTranslation} from "react-i18next"
const SingleArtwork = ({params}) => {
    const { addToCart} = useCart()
    const { t } = useTranslation()
    const [cartShaking, setCartShaking] = useState(false)
    // const [isFavorited, setIsFavorited] = useState(false)
    // useEffect(() => {
    //     const savedFavoriteStatus = localStorage.getItem(`favorite_${params.artworkId}`)
    //     if (savedFavoriteStatus !== null) {
    //         setIsFavorited(savedFavoriteStatus === 'true')
    //     }
    // }, [])
    // const toggleFavorite = () => {
    //     const updatedFavoriteStatus = !isFavorited
    //     setIsFavorited(updatedFavoriteStatus)
    //     localStorage.setItem(`favorite_${params.artworkId}`, updatedFavoriteStatus)
    // }
    const addItemToCart = (art) => {
        addToCart(art)
        setCartShaking((prevCartShaking) => ({...prevCartShaking, [art.artworkId]: true }))
        setTimeout(() => {
            setCartShaking((prevCartShaking) => ({...prevCartShaking, [art.artworkId]: false }))
        }, 1000)
    }
    return(
        <div className="container m-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 pt-12">
            <div key={params.artworkId} className="artwork-box box">
                {params.status === 'SOLD' && (
                    <div className="box"><div className="ribbon"><span>{t('galleryPage.sold')}</span></div></div>
                )}
                <div className="artwork-image">
                    <Link to={`/gallery/${params.artworkId}`}>
                        <img src={params.cloudinaryImageUrl} alt={params.description}
                             loading="lazy" className="rounded-2xl" />
                    </Link>
                </div>
                <div className="artwork-footer">
                    <figcaption className="artwork-title">{params.name}</figcaption>
                    <p className="artwork-price">{t('galleryPage.price')}{`: $${params.price}`}</p>
                    <p className="artwork-dimensions">{t('galleryPage.length')}{`: ${params.length} in. x `}{t('galleryPage.height')}{`: ${params.height} in.`}
                    </p>
                    {params.status === 'LISTED' &&
                        <div className="artwork-icons">
                            {/*<FontAwesomeIcon*/}
                            {/*    icon={isFavorited ? solidHeart : regularHeart}*/}
                            {/*    className={`heart-icon ${isFavorited ? 'favorited' : ''}`}*/}
                            {/*    onClick={toggleFavorite}*/}
                            {/*/>*/}
                            <FontAwesomeIcon icon={faShoppingCart}
                                             className={`cart-icon ${cartShaking[params.artworkId] ? 'shake' : ''}`}
                                             onClick={() => addItemToCart(params)} title={t('galleryPage.addCart')}>
                            </FontAwesomeIcon>
                        </div>}
                </div>
            </div>
        </div>
    )
}
export default SingleArtwork