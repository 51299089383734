import React, {useState, useEffect} from 'react'
import {backendUrlApiGateway} from "../constants"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash, faPlus} from '@fortawesome/free-solid-svg-icons'
import Pica from 'pica'
import {useKeycloak} from "@react-keycloak/web";
const FileUpload = ({onImageUpload, onImageDelete, multiple = false, initialImages = [], uploadType}) => {
    const {keycloak} = useKeycloak()
    const [selectedFiles, setSelectedFiles] = useState([])
    useEffect(() => {
        if (initialImages && initialImages.length > 0) {
            const initialFilePreviews = initialImages.map((url) => ({preview: url}))
            setSelectedFiles(initialFilePreviews)
        }
    }, [initialImages])
    const maxImages = 25
    const maxFileSize = 5 * 1700 * 1700
    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files)
        let oversizedFiles = files.filter(file => file.size > maxFileSize)
        if (oversizedFiles.length > 0) {
            alert(`Some files exceed the maximum size limit of ${maxFileSize / 1024 / 1024} MB and will not be uploaded.`)
            return
        }
        const newTotal = selectedFiles.length + files.length
        if (newTotal > maxImages) {
            alert(`You can only upload up to ${maxImages} images. Some images have not been added.`)
            return
        }
        //resize and compress images
        const resizedFiles = await Promise.all(files.map(file => resizeAndCompressImage(file)))
        const newFilesWithPreview = resizedFiles.map(file => ({
            file, preview: URL.createObjectURL(file)
        }))
        if (uploadType === "artworkImages") {
            setSelectedFiles((prev) => [...prev, ...newFilesWithPreview])
        } else if (uploadType === "displayImage") { setSelectedFiles(newFilesWithPreview) }
    }
    const pica = Pica()
    const maxWidth = 1920
    const maxHeight = 1080
    const minWidth = 800
    const minHeight = 600
//create image from a file object
    const createImage = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (e) => {
                const img = new Image()
                img.onload = () => resolve(img)
                img.onerror = reject
                img.src = e.target.result
            }
            reader.readAsDataURL(file)
        })
    }
    const resizeAndCompressImage = async (file) => {
        const img = await createImage(file)
        let newWidth = img.width
        let newHeight = img.height
        //resize if the image is larger than the maximum dimensions
        if (img.width > maxWidth || img.height > maxHeight) {
            alert("Image dimensions exceed the maximum allowed. It will be resized.")
            if (img.width > img.height) {
                newWidth = maxWidth
                newHeight = (img.height / img.width) * maxWidth
            } else {
                newHeight = maxHeight
                newWidth = (img.width / img.height) * maxHeight
            }
        }
        //ensure the image is not smaller than minimum dimensions
        if (newWidth < minWidth) {
            newWidth = minWidth
            newHeight = (newWidth / img.width) * img.height
        }
        if (newHeight < minHeight) {
            newHeight = minHeight
            newWidth = (newHeight / img.height) * img.width
        }
        //create an off-screen canvas for resizing
        const offscreenCanvas = document.createElement('canvas')
        offscreenCanvas.width = newWidth
        offscreenCanvas.height = newHeight
        //use pica to resize and compress the image
        return pica.resize(img, offscreenCanvas, {
            unsharpAmount: 80,
            unsharpRadius: 0.6,
            unsharpThreshold: 2,
            quality: 3,
        })
            .then(result => pica.toBlob(result, 'image/jpeg', 0.8))
            .then(blob => new File([blob], file.name, {type: 'image/jpeg'}))
    }
    const handleDelete = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this image?")
        if (confirmDelete) {
            const fileToDelete = selectedFiles[index]
            if (fileToDelete && fileToDelete.preview) {
                URL.revokeObjectURL(fileToDelete.preview)
                setSelectedFiles(current => current.filter((_, i) => i !== index))
                if (onImageDelete && fileToDelete.preview) {
                    onImageDelete(fileToDelete.preview)
                }
            }
        }
    }
    const uploadImage = async (fileObj) => {
        if (fileObj.file) {
            const reader = new FileReader()
            reader.onloadend = async () => {
                const base64String = reader.result.split(',')[1]
                try {
                    const response = await fetch(`${backendUrlApiGateway}image`, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'text/plain',
                            'Authorization':`Bearer ${keycloak.token}`
                        },
                        body: base64String
                    })
                    const imageUrl = await response.text()
                    onImageUpload(imageUrl)
                } catch (error) {
                    console.error('Error uploading image:', error)
                }
            }
            reader.readAsDataURL(fileObj.file)
        }
    }
    return (
        <div>
            <input
                type="file"
                id={`file-upload-input-${uploadType}`}
                onChange={handleFileChange}
                multiple={multiple}
                style={{display: 'none'}}
            />
            <button
                type="button"
                onClick={() => document.getElementById(`file-upload-input-${uploadType}`).click()}
                className="btn btn primary"
                style={{marginBottom: '15px'}}
            >
                <FontAwesomeIcon icon={faPlus}/> Add More Files
            </button>
            <div className="image-previews">
                {selectedFiles.map((fileObj, index) => (
                    <div key={index} className="image-preview">
                        <img src={fileObj.preview} alt={`Preview ${index}`} className="img-fluid" style={{ width: '30rem', height: 'auto' }}/>
                        <button type="button" onClick={() => handleDelete(index)}
                                className="btn btn-danger delete-button"><FontAwesomeIcon icon={faTrash} size="2x"/>
                        </button>
                    </div>
                ))}
            </div>
            <button type="button" onClick={() => selectedFiles.forEach(uploadImage)} style={{
                backgroundColor: "#8393d7",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
            }}>
                Upload Image(s)
            </button>
        </div>
    )
}
export default FileUpload