import React, {useState} from "react"
import {Link} from "react-router-dom"
import Profile from "./Profile"
import {useCart} from "./CartContext"
import {useTranslation} from 'react-i18next'
import LanguageSwitcher from '../LanguageSwitcher'
import {Navbar, Nav, NavDropdown} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faShoppingCart, faPalette} from "@fortawesome/free-solid-svg-icons"
import {useKeycloak} from "@react-keycloak/web"
import { FaUserCircle } from "react-icons/fa"

const Header = () => {
    const {keycloak} = useKeycloak()
    const {cartItems} = useCart()
    const cartCount = cartItems.length
    const [showDropdown, setShowDropdown] = useState(false)
    const {t} = useTranslation()

    const handleGalleryMouseEnter = (e) => {
        const container = e.currentTarget
        container.style.color = "gray"
        const galleryIcon = container.querySelector(".gallery-icon")
        if (galleryIcon) {
            galleryIcon.style.color = "gray"
        }
    }

    const handleGalleryMouseLeave = (e) => {
        const container = e.currentTarget
        container.style.color = "white"
        const galleryIcon = container.querySelector(".gallery-icon")
        if (galleryIcon) {
            galleryIcon.style.color = "white"
        }
    }

    const handleCartMouseEnter = (e) => {
        const cartIcon = e.currentTarget.querySelector("svg")
        if (cartIcon) {
            cartIcon.style.color = "gray"
        }
    }

    const handleCartMouseLeave = (e) => {
        const cartIcon = e.currentTarget.querySelector("svg")
        if (cartIcon) {
            cartIcon.style.color = "white"
        }
    }

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown)
    }

    const handleDropdownClose = () => {
        setShowDropdown(false)
    }

    const handleLogout = () => {
        if (keycloak.authenticated) {
            keycloak.logout({ redirectUri: window.location.origin + '/gallery' })
        }
    }

    const cartBadgeStyle = {
        fontSize: "18px",
        fontWeight: "bold",
        backgroundColor: cartCount >= 1 ? "#28a745" : "#dc3545",
        color: "#fff",
        borderRadius: "50%",
        padding: "4px 8px",
        marginLeft: "5px"
    }

    return (
        <Navbar
            bg="dark"
            expand="xl"
            className="header py-3 z-3"
            style={{position: "sticky", top: 0}}
        >
            <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
                <img
                    src="/RPA_Header_Icon.png"
                    height="40"
                    alt="Flowbite Logo"
                    style={{marginLeft: "20px", marginRight: "20px"}}
                />
                <span
                    style={{fontSize: "24px", display: "inline-block", color: "white"}}
                    className="parisienne-regular"
                >
                    RabiaPatelArt
                </span>
            </Navbar.Brand>
            <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="ms-auto"
                style={{
                    marginLeft: "auto",
                    marginRight: "20px",
                    fontSize: "15px",
                    marginTop: "0px",
                    color: "grey",
                    backgroundColor: "white"
                }}
            />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto playfair-display-italic" style={{margin: "auto"}}>
                    <Nav.Link
                        as={Link}
                        to="/gallery"
                        style={{marginRight: "30px", fontSize: "18px", textDecoration: "none", color: "white"}}
                        onMouseEnter={handleGalleryMouseEnter}
                        onMouseLeave={handleGalleryMouseLeave}
                    >
                        <div>
                            {t('header.gallery')}
                            <FontAwesomeIcon
                                icon={faPalette}
                                size="1x"
                                className="mr-2 gallery-icon"
                                style={{marginLeft: "5px"}}
                            />
                        </div>
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/about"
                        style={{marginRight: "30px", fontSize: "18px", color: "white", textDecoration: "none"}}
                        onMouseEnter={(e) => e.target.style.color = "gray"}
                        onMouseLeave={(e) => e.target.style.color = "white"}
                    >
                        {t('header.about')}
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/contact"
                        style={{marginRight: "30px", fontSize: "18px", color: "white", textDecoration: "none"}}
                        onMouseEnter={(e) => e.target.style.color = "gray"}
                        onMouseLeave={(e) => e.target.style.color = "white"}
                    >
                        {t('header.contact')}
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/cart"
                        style={{marginRight: "30px", fontSize: "18px", color: "white", textDecoration: "none"}}
                        onMouseEnter={handleCartMouseEnter}
                        onMouseLeave={handleCartMouseLeave}
                    >
                        <FontAwesomeIcon
                            icon={faShoppingCart}
                            size="1x"
                            className="mr-2"
                        />
                        <span className="badge" style={cartBadgeStyle}>
                            {cartCount}
                        </span>
                    </Nav.Link>
                    <div style={{marginLeft: "30px"}}>
                        <LanguageSwitcher/>
                    </div>
                    <Nav.Item style={{
                        cursor: "pointer",
                        marginRight: "30px",
                        marginTop: "8px",
                        fontSize: "18px",
                        textDecoration: "none"
                    }}>
                        {!keycloak.authenticated && (
                            <p
                                onClick={() => keycloak.login()}
                                style={{color: "white"}}
                                onMouseEnter={(e) => e.target.style.color = "gray"}
                                onMouseLeave={(e) => e.target.style.color = "white"}
                            >
                                {t('header.logIn')}
                            </p>
                        )}
                    </Nav.Item>
                    {keycloak.authenticated  && (


                    <NavDropdown
                        title={<FaUserCircle size={32} style={{color: 'white'}}/>}
                        id="basic-nav-dropdown"
                        show={showDropdown }
                        onToggle={handleDropdownToggle}
                        onMouseLeave={handleDropdownClose}
                    >
                        {keycloak.tokenParsed.realm_access.roles.includes('RAB') && (
                                <>
                            <NavDropdown.Item as={Link} to="/profile-page">
                                    Profile
                                </NavDropdown.Item>

                                <NavDropdown.Divider />
                                </>
                            )}
                                <NavDropdown.Item onClick={handleLogout}>
                                    {t('header.logOut')}
                                </NavDropdown.Item>
                    </NavDropdown>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
export default Header