const JSONParser = (data) => {
    const lines = data.split('\n').filter((line) => line.trim() !== '')
    const parsedArtworks = lines.map((line) => {
            try { return JSON.parse(line.replace('data:', ''))
            } catch (error) { console.error('Error parsing JSON:', error)
                return null
            }
        }).filter((artwork) => artwork !== null)
    return parsedArtworks
}
export default JSONParser