import React, { useEffect, useState } from 'react'
import axios from 'axios'
import SingleArtwork from "./SingleArtwork"
import { useCart } from './CartContext'
import { backendUrlApiGateway } from '../constants.js'
import JSONParser from "./JSONParser"
import {Image} from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'


// import {Link} from 'react-router-dom'
// import Profile from "./Profile"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faHeart, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
// import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
const Artworks = () => {
    const { addToCart, showWarning } = useCart()
    const [arts, setArts] = useState([])
    const [cartShaking, setCartShaking] = useState(false)
    const { t, i18n } = useTranslation()
    const backgroundImages = {
        en: './images/en/RabiaPatelArtGallery.png',
        fr: './images/fr/WelcometoRabiaPatelArtFR.png',
    }

    // const { isAuthenticated } = useAuth0()
    const backgroundImageUrl = backgroundImages[i18n.language] || backgroundImages.en
    useEffect(() => {
        (async () => {
            try {
                axios.get(`${backendUrlApiGateway}artworks/displayed`, {
                    accept: "application/json", 'Content-Type': 'text/event-stream-value;charset=UTF-8'
                }).then(res => { setArts(JSONParser(res.data)) })
            } catch (error) { console.error('Error fetching data:', error) }
        })()
    }, [])

    function statusVerifier(param) { return <SingleArtwork params={param}/> }

    const addItemToCart = (art) => {
        addToCart(art)
        setCartShaking((prevCartShaking) => ({ ...prevCartShaking, [art.artworkId]: true }))
        setTimeout(() => {
            setCartShaking((prevCartShaking) => ({ ...prevCartShaking, [art.artworkId]: false }))
        }, 1000)
    }
    return (
        <div>
            <Image className="GalleryHeaderImg" src={backgroundImageUrl} alt="RPA_Header"/>
            <h1 className="all-artworks-header dancing-script"
                style={{fontSize: "50px"}}>{t('galleryPage.allArtworksHeader')}</h1>
            <div className="artwork-gallery playfair-display-italic">
                {arts.map((art) => (
                    <React.Fragment key={art.artworkId}>
                        <SingleArtwork params={art}/>
                    </React.Fragment>
                ))}
            </div>
            {showWarning && <div className="WarningContainer"><p className="WarningText">{t('galleryPage.itemAlreadyInCart')}</p></div>}
        </div>
    )
}
export default Artworks
