import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'

function ArtworkCarousel({ artworkImages, cloudinaryImageUrl }) {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  let imagesToDisplay = artworkImages
  if (cloudinaryImageUrl) {
    imagesToDisplay = [cloudinaryImageUrl, ...artworkImages]
  }

  return (
      <Carousel activeIndex={index} onSelect={handleSelect} data-bs-theme="dark" className="justify-content-center">
        {imagesToDisplay.map((image, idx) => (
            <Carousel.Item key={idx} className="text-center">
              <img src={image} alt={`Slide ${idx}`} style={{ margin: 'auto', display: 'block' }} />
            </Carousel.Item>
        ))}
      </Carousel>
  )
}

export default ArtworkCarousel