import React, {useEffect, useState} from 'react'
import axios from "axios"
import {backendUrlApiGateway, squareAppId, squareFormScript, squareLocationId} from "../constants"
import '../Payment.css'
import {CreditCard, PaymentForm} from "react-square-web-payments-sdk";
import { useTranslation } from 'react-i18next'
import keycloak from "./auth/keycloak"
import {useKeycloak} from "@react-keycloak/web"
const SquarePaymentComponent = ({artworkIds, totalPrice}) => {
    const { keycloak} = useKeycloak()
    const [payments, setPayments] = useState(null)
    const [card, setCard] = useState(null)
    // const [cardButton, setCardButton] = useState(null)
    const appId = squareAppId
    const locationId = squareLocationId
    const { t } = useTranslation()

    const initializeCard = async (paymentsInstance) => {
        const cardInstance = await paymentsInstance.card()
        await cardInstance.attach('#card-container')
        setCard(cardInstance)
    }
    //const createPayment = async (token, verificationToken) => {
    //{keycloak.authenticated && (
        const createPayment = async (token, verificationToken) => {

            const body = {
                customerId: "user",
                //customerId: keycloak.tokenParsed.email,
                artworkIds: artworkIds,
                card: token
            }
            try {
                const paymentResponse = await axios.post(
                    `${backendUrlApiGateway}payments`, body, {
                        //"Content-Type": "application/json",
                    // headers: {
                    //     "Authorization": `Bearer ${keycloak.token}`
                    // }
                })
                //     method: 'POST',
                // headers: {
                //     // authorization: "Bearer ",
                //     'Content-Type': 'application/json'
                // },
                // body
                // })
                if (200 === paymentResponse.status) { // return paymentResponse.json()
                }
                // const errorBody = await paymentResponse.text()
                // throw new Error(errorBody)
            } catch (error) {
                throw new Error(error.message)
            }
        }
//)}

    const tokenize = async (paymentMethod) => {
        const tokenResult = await paymentMethod.tokenize()
        if (tokenResult.status === 'OK') { return tokenResult.token
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`
            if (tokenResult.errors) { errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}` }
            throw new Error(errorMessage)
        }
    }
    const verifyBuyer = async (paymentsInstance, token) => {
        const verificationDetails = {
            amount: '1.00',
            billingContact: {
                givenName: 'John',
                familyName: 'Doe',
                email: 'john.doe@square.example',
                phone: '3214563987',
                addressLines: ['123 Main Street', 'Apartment 1'],
                city: 'Canada',
                state: 'QC',
                countryCode: 'CA',
            },
            currencyCode: 'CAD',
            intent: 'CHARGE',
        }
        const verificationResults = await paymentsInstance.verifyBuyer(token, verificationDetails)
        return verificationResults.token
    }
    const displayPaymentResults = (status) => {
        // const statusContainer = document.getElementById('payment-status-container')
        // if (status === 'SUCCESS') {
        //     statusContainer.classList.remove('is-failure')
        //     statusContainer.classList.add('is-success')
        // } else {
        //     statusContainer.classList.remove('is-success')
        //     statusContainer.classList.add('is-failure')
        // }
        // statusContainer.style.visibility = 'visible'
    }
    useEffect(() => {

        const loadSquareScript = () => {
            const script = document.createElement('script')
            script.src = squareFormScript
            script.async = true
            document.head.appendChild(script)
            script.onload = () => {
                // if (!payments) {
                    initializeSquarePayments()
                // }
            }
        }
        const initializeSquarePayments = () => {
            if (!window.Square) { throw new Error('Square.js failed to load properly') }
            try {
                const paymentsInstance = window.Square.payments(appId, locationId)
                setPayments(paymentsInstance)
                initializeCard(paymentsInstance)
            } catch {
                const statusContainer = document.getElementById('payment-status-container')
                statusContainer.className = 'missing-credentials'
                statusContainer.style.visibility = 'visible'
            }
        }
        loadSquareScript()
    }, [])
    const handlePaymentMethodSubmission = async () => {
        try {
            // document.getElementById('cardButton').disabled = true
            const token = await tokenize(card)
            const verificationToken = await verifyBuyer(payments, token)
            const paymentResults = await createPayment(token, verificationToken)
            displayPaymentResults('SUCCESS')
            console.debug('Payment Success', paymentResults)
        } catch (error) {
            // document.getElementById('cardButton').disabled = false
            displayPaymentResults('FAILURE')
            console.error(error.message)
        }
    }
    return (
        // <div>
        //     {/* Payment form */}
        //     <form id="payment-form">
        //         <div id="card-container"></div>
        //         <button id="card-button" type="button" onClick={handlePaymentMethodSubmission}>
        //             Pay ${price}
        //         </button>
        //     </form>
        //     {/* Payment status display */}
        //     <div id="payment-status-container"></div>
        //     {/* Display cart items */}
        //     <div className="cart-items">
        //         <h2>Cart Summary</h2>
        //         <ul>
        //             {cartItems.map((item) => (
        //                 <li key={item.artworkId}> {item.name} - ${item.price} </li>
        //             ))}
        //         </ul>
        //         <p>Total: ${calculateTotal(cartItems)}</p>
        //     </div>
        // </div>
        <div>
            <form id="payment-form">
                <div id="card-container"></div>
                <button id="card-button" type="button" onClick={handlePaymentMethodSubmission}>{t('payment.payButton')} ${totalPrice}</button>
            </form>
            {/*<div id="payment-status-container"></div>*/}
            {/*<div className="total-price">*/}
            {/*    <h2>Total Price: ${totalPrice}</h2>*/}
            {/*</div>*/}
            {/*{cartItems.length > 0 && (*/}
            {/*    <div className="cart-summary">*/}
            {/*        <h2>Cart Summary</h2>*/}
            {/*        <ul>*/}
            {/*            {cartItems.map((item) => (*/}
            {/*                <li key={item.artworkId}> {item.name}: ${item.price} </li>*/}
            {/*            ))}*/}
            {/*        </ul>*/}
            {/*        <p>Total Price: ${totalPrice.toFixed(2)}</p>*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
//new payment form with square react sdk
    // <div className="payment-form-container">
    //     <PaymentForm
    //         applicationId={appId}
    //         locationId={locationId}
    //         cardTokenizeResponseReceived={(token, verifiedBuyer) => {
    //             console.log('token:', token);
    //             console.log('verifiedBuyer:', verifiedBuyer);
    //         }}
    //     >
    //         <CreditCard />
    //         {/*<button type="button">*/}
    //         {/*    {t('payment.payButton')} ${totalPrice}*/}
    //         {/*</button>*/}
    //     </PaymentForm>
    // </div>
    )
}
export default SquarePaymentComponent