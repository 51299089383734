import SquarePaymentComponent from "./SquarePaymentComponent"
import {useNavigate} from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useCart } from "./CartContext"
const CheckoutPage = () => {
    const navigate = useNavigate()
    const handleBack = () => { navigate(-1) }
    const { t } = useTranslation()
    const { cartItems } = useCart()
    const artworkIds = cartItems.map((item) => item.artworkId)
    const totalPrice = cartItems.reduce((total, item) => total + item.price, 0)
    return (
        <div>

            <h1>{t('checkoutPage.checkoutPageTitle')}</h1>
            <SquarePaymentComponent artworkIds={artworkIds} totalPrice={totalPrice}/>
            <button type="button" onClick={handleBack}
                    style={{
                        marginRight: '10px',
                        backgroundColor: '#3498db',
                        color: '#ffffff',
                        padding: '10px 15px',
                        borderRadius: '5px',
                        marginBottom: '20px',
                        marginTop: '20px',
                        marginLeft: '5px',
                    }}>{t('checkoutPage.back')}</button>
        </div>
    )
}
export default CheckoutPage