import React, { createContext, useContext, useState, useEffect } from 'react'
import WarningMessage from './WarningMessage'
import { useTranslation } from 'react-i18next'

const CartContext = createContext()

export function CartProvider({ children }) {
    const [cartItems, setCartItems] = useState([])
    const [showWarning, setShowWarning] = useState(false)
    const [duplicateAddition, setDuplicateAddition] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || []
        setCartItems(storedCart)
    }, [])

    const addToCart = (artwork) => {
        const existingCartItem = cartItems.find((item) => item.artworkId === artwork.artworkId)

        if (existingCartItem) {
            setShowWarning(true)
            setTimeout(() => setShowWarning(false), 2000)
            setDuplicateAddition(true)
            setTimeout(() => setDuplicateAddition(false), 500)
            return
        }
        const cartItem = {
            artworkId: artwork.artworkId,
            name: artwork.name,
            cloudinaryImageUrl: artwork.cloudinaryImageUrl,
            price: artwork.price,
            length: artwork.length,
            height: artwork.height,
            weight: artwork.weight,
        }

        const updatedCart = [...cartItems, cartItem]
        setCartItems(updatedCart)
        localStorage.setItem('cart', JSON.stringify(updatedCart))
    }

    const removeFromCart = (itemId) => {
        const updatedCart = cartItems.filter((item) => item.artworkId !== itemId)
        setCartItems(updatedCart)
        localStorage.setItem('cart', JSON.stringify(updatedCart))
    }

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, showWarning, duplicateAddition }}>
            {children}
            {showWarning && <WarningMessage message={t('galleryPage.itemAlreadyInCart')} />}
        </CartContext.Provider>
    )
}

export function useCart() {
    return useContext(CartContext)
}
