import "../App.css"
import ErrorDrawing from "./images/ErrorDrawing.png"
import { useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button"

const ErrorPage = () => {
    const navigate = useNavigate()

    function normalBackground(e) {
        e.target.style.background = 'lightsteelblue'
    }
    function changeBackground(e) {
        e.target.style.background = '#4e6f91'
    }

    return (
        <div style={{textAlign: "center", margin: "100px"}}>
            <img className="imageSize" src={ErrorDrawing}
                 style={{
                     width: '21%',
                     minWidth: '19rem'
            }}
                 alt="Error"/>
            <br/><br/>
            <strong>Sorry, an error occurred.</strong><br/><br/>
            <strong>The page that you are trying to access does not exist or is unavailable</strong><br/>
            <Button onClick={() => window.location = '/gallery'}
                    style={{
                        textDecoration: 'none',
                        background: 'lightsteelblue',
                        borderColor: '#2a2525',
                        color: '#fff',
                        margin: '0.5em',
                        padding: '0.7em 1.7em',
                        display: 'inline-block',
                        borderRadius: '25px',
                        width: 'fit-content',
                        textTransform: 'uppercase',
                        transition: '0.4s'
                    }} onMouseOver={changeBackground} onMouseLeave={normalBackground}>
                Home
            </Button>
            <Button onClick={() => navigate(-1)}
                    style={{
                        textDecoration: 'none',
                        background: 'lightsteelblue',
                        borderColor: 'black',
                        color: '#fff',
                        margin: '0.5em',
                        padding: '0.7em 1.7em',
                        display: 'inline-block',
                        borderRadius: '25px',
                        width: 'fit-content',
                        textTransform: 'uppercase',
                        transition: '0.4s'
                    }} onMouseEnter={changeBackground} onMouseLeave={normalBackground}>
                Back
            </Button>
        </div>
    )
}
export default ErrorPage