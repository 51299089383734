import React from 'react'
import { Container, Row, Col, Image, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faPaintBrush, faImages } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next"
import './Cart.css'

export default function About() {
    const { t } = useTranslation()
    const contentParagraphs = t('aboutPage.content').split('\n\n')

    // const cardBodyStyles = {
    //     marginLeft: 'initial'
    // }

    return (
        <Container className="about-us-container py-5">
            <Row className="about-us-content justify-content-center align-content-center">
                <Col md={12} className="about-us-text text-center">
                    <h1 className="text-xl font-bold mb-4 text-gray-900 parisienne-regular"
                        style={{ fontSize: "60px"}}>{t('aboutPage.title')}</h1>
                    <Row>
                        <Col md={8}>
                            {contentParagraphs.map((paragraph, index) => (
                                <p key={index} className="about-paragraph">
                                    {paragraph}
                                </p>
                            ))}
                        </Col>
                        <Col md={4}>
                            <Image src="https://res.cloudinary.com/rabiapatelart/image/upload/v1708457572/rabia_tpl9dr.png" alt="Rabia Patel Profile" className="profile-image" />
                            <figcaption className="mt-2 location">Greenfield Park, QC, Canada</figcaption>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} className="mb-4 mt-4 text-center">
                    <FontAwesomeIcon icon={faGraduationCap} className="icon mb-3" size="2x" />
                    <h2 className="text-xl font-bold mb-4 text-gray-900 parisienne-regular">{t('aboutPage.educationTitle')}</h2>
                    <p className="section-content">- Political Science, University of Mauritius</p>
                    <p className="section-content">- Social Science, Administration, Art, Cambridge UK</p>
                </Col>
                <Col md={4} className="mb-4 mt-4 text-center">
                    <FontAwesomeIcon icon={faPaintBrush} className="icon mb-3" size="2x" />
                    <h2 className="text-xl font-bold mb-4 text-gray-900 parisienne-regular">{t('aboutPage.eventTitle')}</h2>
                    <p className="section-content">- {t('aboutPage.events')}</p>
                </Col>
                <Col md={4} className="mb-4 mt-4 text-center">
                    <FontAwesomeIcon icon={faImages} className="icon mb-3" size="2x" />
                    <h2 className="text-xl font-bold mb-4 text-gray-900 parisienne-regular">{t('aboutPage.exhibitionTitle')}</h2>
                    <p className="section-content">- Group Exhibition: "Effervescence de la création," 2017, Brossard, Quebec</p>
                </Col>
            </Row>
        </Container>
    )
}