import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPaintBrush, faInfoCircle, faShippingFast, faPalette } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-links">
                    <a href="/contact"><FontAwesomeIcon icon={faEnvelope} /> {t('header.contact')}</a>
                    <a href="/commission-form"><FontAwesomeIcon icon={faPaintBrush} /> {t('commissionPage.commissionRequest')}</a>
                    <a href="/about"><FontAwesomeIcon icon={faInfoCircle} /> {t('header.about')}</a>
                </div>
                <div className="footer-policies">
                    <div className="shipping-returns-box">
                        <div className="icon-container">
                            <FontAwesomeIcon icon={faShippingFast} size="lg" />
                        </div>
                        <div className="shipping-returns-details">
                            <p><strong>{t('footerPage.shippingReturns')}</strong></p>
                            <p>{t('footerPage.returnsPolicy')}<a href="/contact">{t('footerPage.helpSection' )} </a> {t('footerPage.forMoreInfo')}.</p>
                            <p>{t('footerPage.shipsFrom')}</p>
                            <p>{t('footerPage.additionalQuestions')} <a href="/contact">{t('footerPage.helpSection' )} </a> {t('footerPage.or' )} <a href="/contact">{t('contactPage.getInTouch')}</a>.</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="footer-bottom">
                <p>&#169; {new Date().getFullYear()} {t('footerPage.copyright')}: <a href="https://rabiapatelart.com/"><FontAwesomeIcon icon={faPalette} /> RabiaPatelArt.com</a></p>
            </div>
        </footer>
    )
}
export default Footer