import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import keycloakop from "./components/auth/keycloak"
import {ReactKeycloakProvider} from "@react-keycloak/web"

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <ReactKeycloakProvider
        authClient={keycloakop}
        LoadingComponent={<div className="loading-message">Loading...</div>}
    >
        <App />
    </ReactKeycloakProvider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
