import React, {useState} from 'react'
import axios from 'axios'
import Alert from 'react-bootstrap/Alert'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faPaperPlane, faShippingFast, faBrush } from '@fortawesome/free-solid-svg-icons';
import {backendUrlApiGateway} from '../constants.js'
import {Link} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {useKeycloak} from "@react-keycloak/web"
import { Container, Row, Col, Card } from "react-bootstrap";
const ContactPage = () => {
    const { t } = useTranslation()
    const { keycloak } = useKeycloak()
    const [formData, setFormData] = useState({ name: '', email: '', message: '' })
    const navigate = useNavigate()
    const [alert, setAlert] = useState(null)
    const handleChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('Form Data:', formData)
        axios.post(`${backendUrlApiGateway}mail`, formData)
            .then(() => {
                setAlert({ variant: 'success', message: 'Message sent successfully!' })
                window.setTimeout(() => { setAlert(null) }, 5000)
            })
            .catch(() => {
                setAlert({ variant: 'danger', message: 'Error sending Message!' })
                window.setTimeout(() => { setAlert(null) }, 5000)
            })
    }
    const handleAuthenticatedCommissionPage = () => {
        if (!keycloak.authenticated) {
            keycloak.login()
        } else {
            navigate('/add-commission')
        }
    }
    const scrollToForm = () => {
        document.getElementById('contact-form').scrollIntoView({ behavior: 'smooth' });
    };

    const backgroundStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/MountainBackground.png)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        // Add your other styles here
    };
    return (

        <Container fluid className="contact-page-container py-5">
            <Row className="justify-content-center align-items-start">
                <Col lg={6} md={12}>
                    {alert && (<Alert variant={alert.variant}>{alert.message}</Alert>)}
                    <form id="contact-form" onSubmit={handleSubmit}>
                        <h1 className="flex items-center m-lg-2 dancing-script">{t('contactPage.getInTouch')}</h1>
                        <FontAwesomeIcon icon={faPaperPlane} size="3x" className="text-blue-500 m-lg-4" />
                        <div className="mb-4">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                                placeholder={t('contactPage.yourname')}
                                required
                                style={{ width: '50%' }}
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="form-input p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                                placeholder={t('contactPage.youremail')}
                                required
                                style={{ width: '50%' }}
                            />
                        </div>
                        <div className="mb-4">
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="form-textarea p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                        placeholder={t('contactPage.yourmessage')}
                        rows="4"
                        required
                        style={{ width: '50%' }}
                    ></textarea>
                        </div>
                        <Button as="input" type="submit"  style={{marginRight: '10px',
                            backgroundColor: '#3498db',
                            color: '#ffffff',
                            padding: '10px 15px',
                            borderRadius: '5px',
                            marginBottom: '20px',
                            marginTop: '20px',
                            marginLeft: '5px',}}value={t('contactPage.sendMessage')}/>
                    </form>

                </Col>
                <Col lg={6} md={12} className="text-center">
                    <img src="https://res.cloudinary.com/rabiapatelart/image/upload/v1708574506/paint_i5ixwq.jpg" alt="Artwork" style={{ maxWidth: '100%', height: 'auto', borderRadius: '15px' }} />
                </Col>
            </Row>
            <h1 className="flex items-center m-lg-2 dancing-script">{t('contactPage.needHelp')}</h1>
            <Row className="justify-content-center text-center mb-5">
                <Col lg={3} md={6} sm={12}>
                    <Card className="contact-card">
                        <Card.Body>
                            <FontAwesomeIcon icon={faShippingFast} size="3x" className="contact-icon" />
                            <Card.Title>{t('contactPage.shippingReturns')}</Card.Title>
                            <Card.Text>
                                {t('contactPage.deliveryTime')}<br></br>
                                {t('contactPage.returnsPolicy')}
                                {t('contactPage.deliveryCost')}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} md={6} sm={12}>
                    <Card className="contact-card mx-auto">
                        <Card.Body>
                            <FontAwesomeIcon icon={faEnvelope} size="3x" className="contact-icon" />
                            <Card.Title>{t('contactPage.generalInquiries')}</Card.Title>
                            <Card.Text>
                                {t('contactPage.getInTouchWithUs')}
                            </Card.Text>
                            <Button variant="primary" onClick={scrollToForm}>{t('contactPage.askUs')}</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} md={6} sm={12}>
                    <Card className="contact-card">
                        <Card.Body>
                            <FontAwesomeIcon icon={faBrush} size="3x" className="contact-icon" />
                            <Card.Title>{t('contactPage.commissionForm')}</Card.Title>
                            <Card.Text>
                                {t('contactPage.interestedInCustomArtwork')}
                            </Card.Text>
                            <Button variant="primary" onClick={handleAuthenticatedCommissionPage}>{t('contactPage.commissionFormButton')}</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

    <Row className="text-center mt-4">
        <Col>
            <h2 className="text-xl font-bold mb-4 text-gray-900 parisienne-regular">{t('contactPage.contactInfo')}:</h2>
            <p className="text-gray-600">
                <FontAwesomeIcon icon={faEnvelope} style={{marginRight: "5px"}} />
                {t('contactPage.email')}: rabiapatelart@gmail.com
            </p>
        </Col>
    </Row>
        </Container>
    )
}
export default ContactPage
