import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {backendUrlApiGateway} from '../constants.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBusinessTime, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from "react-i18next"
import {Container, Form} from 'react-bootstrap'
import './Forms.css'
import numeral from 'numeral'
import {useKeycloak} from "@react-keycloak/web";
import keycloak from "../components/auth/keycloak";

numeral.defaultFormat('$0,0.00')

const emptyCommissionData = {
    commissionTitle: '',
    fullName: '',
    userEmail: '',
    artworkLength: '',
    artworkHeight: '',
    startDate: '',
    artworkDescription: ''
};

const CommissionRequestForm = () => {
    const { keycloak } = useKeycloak()
    const navigate = useNavigate()
    const {t} = useTranslation();
    const [commissionData, setCommissionData] = useState(emptyCommissionData);

    // useEffect(() => {
    //     if (!keycloak.authenticated) {
    //         navigate('/*');
    //     }
    // }, [keycloak.authenticated, navigate])

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setCommissionData({...commissionData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submit button clicked');
        if (
            !commissionData.commissionTitle.trim() ||
            !commissionData.fullName ||
            !commissionData.userEmail ||
            !commissionData.artworkLength ||
            !commissionData.artworkHeight ||
            !commissionData.startDate ||
            !commissionData.artworkDescription.trim()
        ) {
            alert(t('commissionPage.fillOutFields'));
            return;
        }
        try {
            const response = await axios.post(
                `${backendUrlApiGateway}commission`, commissionData)
            console.log('Server response:', response.data);
            handleClear();
            alert(t('commissionPage.successMessage'));
        } catch (error) {
            console.error('Error submitting commission request', error);
            if (error.response) {
                console.error('Server responded with:', error.response.data);
                console.error('Status code:', error.response.status);
                alert(t('commissionPage.errorTryAgain'));
            } else if (error.request) {
                console.error('No response received from the server.');
                alert('No response received from the server. Please try again later.');
            } else {
                console.error('Error setting up the request:', error.message);
                alert(t('commissionPage.errorTryAgain'));
            }
            alert(t('commissionPage.errorSubmitting'));
        }
    };

    const handleClear = () => {
        setCommissionData(emptyCommissionData);
    };

    return (
        <Container className="commission-request-form-container py-5">
            <div className="">
                <h1 className="text-3xl font-bold mb-6 text-center dancing-script">{t('commissionPage.commissionRequest')}</h1>
                <FontAwesomeIcon icon={faBusinessTime} size="3x" className="text-blue-500 m-lg-4 mb-2 mr-2"/>
                <p className="w-75 mx-auto">
                    {t('commissionPage.welcomeMessage')}
                </p>
                <Form onSubmit={handleSubmit} className="mb-6 text-center">
                    <div className="d-flex flex-column align-items-center">
                        <Form.Group controlId="commissionTitle" className="mb-4 col-md-6">
                            <Form.Label>{t('commissionPage.commissionTitle')} :</Form.Label>
                            <Form.Control
                                type="text"
                                name="commissionTitle"
                                value={commissionData.commissionTitle}
                                onChange={handleInputChange}
                                placeholder={t('commissionPage.enterCommissionArtworkTitle')}
                                required
                                className="text-center"
                            />
                        </Form.Group>
                        <Form.Group controlId="fullName" className="mb-4 col-md-6">
                            <Form.Label>{t('commissionPage.fullName')}:</Form.Label>
                            <Form.Control
                                type="text"
                                name="fullName"
                                value={commissionData.fullName}
                                onChange={handleInputChange}
                                placeholder={t('commissionPage.enterFullName')}
                                required
                                className="text-center"
                            />
                        </Form.Group>
                        <Form.Group controlId="userEmail" className="mb-4 col-md-6">
                            <Form.Label>{t('commissionPage.email')}:</Form.Label>
                            <Form.Control
                                type="email"
                                name="userEmail"
                                value={commissionData.userEmail}
                                onChange={handleInputChange}
                                placeholder={t('commissionPage.enterEmail')}
                                required
                                className="text-center"
                            />
                        </Form.Group>
                        <Form.Group controlId="artworkLength" className="mb-4 col-md-6">
                            <Form.Label>{t('commissionPage.lengthInches')}:</Form.Label>
                            <Form.Control
                                type="number"
                                name="artworkLength"
                                value={commissionData.artworkLength}
                                onChange={handleInputChange}
                                placeholder={t('commissionPage.enterArtworkLengthInches')}
                                required
                                className="text-center"
                            />
                        </Form.Group>
                        <Form.Group controlId="artworkHeight" className="mb-4 col-md-6">
                            <Form.Label>{t('commissionPage.heightInches')}:</Form.Label>
                            <Form.Control
                                type="number"
                                name="artworkHeight"
                                value={commissionData.artworkHeight}
                                onChange={handleInputChange}
                                placeholder={t('commissionPage.enterArtworkHeightInches')}
                                required
                                className="text-center"
                            />
                        </Form.Group>
                        <Form.Group controlId="startDate" className="mb-4 col-md-6">
                            <Form.Label>{t('commissionPage.startingDate')}:</Form.Label>
                            <Form.Control
                                type="date"
                                name="startDate"
                                value={commissionData.startDate}
                                onChange={handleInputChange}
                                required
                                className="text-center"
                            />
                        </Form.Group>
                        <Form.Group controlId="artworkDescription" className="mb-4 col-md-8">
                            <Form.Label>{t('commissionPage.commissionDetails')}:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="artworkDescription"
                                value={commissionData.artworkDescription}
                                onChange={handleInputChange}
                                placeholder={t('commissionPage.describeArtwork')}
                                required
                                style={{minHeight: '100px'}}
                                className="text-center"

                            />
                        </Form.Group>
                        <div className="mb-4" style={{marginTop: '10px'}}>
                            <Link to="/contact">
                                <button type="button"
                                        style={{
                                            marginRight: '10px',
                                            backgroundColor: '#3498db',
                                            color: '#ffffff',
                                            padding: '10px 15px',
                                            borderRadius: '5px',
                                        }}>{t('commissionPage.return')}</button>
                            </Link>
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                style={{
                                    marginRight: '10px',
                                    backgroundColor: '#3498db',
                                    color: '#ffffff',
                                    padding: '10px 15px',
                                    borderRadius: '5px',
                                }}>{t('commissionPage.sendCommissionRequest')}</button>
                            <button
                                type="button"
                                onClick={handleClear}
                                style={{
                                    backgroundColor: '#3498db',
                                    color: '#ffffff',
                                    padding: '10px 15px',
                                    borderRadius: '5px',
                                }}>{t('commissionPage.clear')}</button>
                        </div>
                        <h2 className="text-2xl font-bold mb-4 mt-4 dancing-script">{t('commissionPage.contactInformation')}</h2>
                        <p className="text-gray-600">
                            <FontAwesomeIcon icon={faEnvelope} className="mr-4"/>
                            Email: rabiapatelart@gmail.com
                        </p>
                    </div>
                </Form>
            </div>
        </Container>
    )
}
export default CommissionRequestForm;